import * as _ from "lodash";
import api from "../../helpers/apiHandler";
import moment from "moment";
/* eslint-disable */
const getDefaultState = () => ({
    "alerts": [],
    "apkInTwoMonths": false,
    "maxSelectableDate": null,
    "invalidLicense": false,
    "callbackAction": false,
    "hideCallbackProduct": false,
    "appointmentType": "",
    "currentEmailAddress": "",
    "currentCarImage": "",
    "currentCarInfo": {},
    "currentContactInfo": {
        "company": "",
        "salutation": "",
        "name": "",
        "postalCode": "",
        "streetName": "",
        "houseNumber": "",
        "city": "",
        "phoneNumber": "",
        "mailAddress": "",
        "pst": "",
        "showNewsLetterCheckbox": null
    },
    "currentKlantNr": "",
    "currentRdwData": {},
    "doAdvice": null,
    "existingCustomer": null,
    "maintenanceDate": null,
    "currentDisabledDates": [],
    "currentTimes": [],
    "maintenanceTime": null,
    "waitForMaintenance": false,
    "currentEstablishmentId": null,
    "currentMechanic": {},
    "currentTransportInfo": {
        "transport": false,
        "type": 1
    },
    "currentDamageRecovery": {
        "carDrivable": true,
        "bigDamage": true,
        "insurance": true,
        "damageForm": true,
        "description": "",
        "damageFormFile": null
    },
    "chosenProducts": [],
    "remarks": "",
    "invoiceMail": false,
    "mechanicalWash": false,
    "maintenanceProducts": [],
    "actionProducts": [],
    "latestNews": false,
    "mechanics": [],
    "Appointment": {
        "CarInfo": {},
        "CheckList": {
            "CheckList": "",
            "KeepReplacedParts": "",
            "MechanicalWash": ""
        },
        "Establishment": {},
        "AppointmentInfo": {
            "Date": "",
            "Time": "",
            "Remarks": "",
            "ReplacementVehicle": 0,
            "Reference": "",
            "SendMailCustomer": 0,
            "SendMailDealer": 0,
            "NoLead": 0,
            "ExternalReplacementVehicle": ""
        },
        "Jobs": [],
        "Fields": {
            "CompanyName": "",
            "LeaseCompanyName": "",
            "Salutation": "",
            "FirstName": "",
            "Name": "",
            "Street": "",
            "HouseNumber": "",
            "ZipCode": "",
            "City": "",
            "Phone": "",
            "Email": "",
            "RegistrationNumber": null,
            "FormattedLicensePlate": "",
            "FormattedWorkshopLicensePlate": "",
            "Make": "",
            "Type": "",
            "Mileage": null,
            "Chassis": "",
            "CollectData": {
                "Street": "",
                "HouseNumber": "",
                "ZipCode": "",
                "City": "",
                "Remark": "",
                "DateTime": ""
            },
            "DeliverData": {
                "Street": "",
                "HouseNumber": "",
                "ZipCode": "",
                "City": "",
                "Remark": "",
                "DateTime": ""
            }
        },
        "Source": "",
        "ExtraInfo": [
            {
                "FieldName": "",
                "Value": ""
            }
        ],
        "damageImages": []
    }
});

const state = getDefaultState();
const mutations = {
    ADD_ALERT (state, alert) {
        if (state.alerts.indexOf(alert) === -1) {
            state.alerts.push(alert);
        }
    },
    REMOVE_ALERT(state, alert) {
        const index = state.alerts.indexOf(alert);
        if (index !== -1) {
            state.alerts.splice(index, 1);
        }
    },
    SELECT_MECHANIC (state, mechanic) {
        const index = state.Appointment.Jobs.findIndex((item) => item.Type === 0);
        if (index > -1) {
            state.Appointment.Jobs.splice(
                index,
                1
            );
        }
        state.Appointment.Jobs.push({
            "JobID": mechanic.Id,
            "Units": mechanic.Units,
            "SkillCode": mechanic.SkillCode,
            "Name": mechanic.Name,
            "Waits": mechanic.Waits,
            "Type": 0,
            "Price": mechanic.Price,
            "DiscountPrice": mechanic.DiscountPrice,
            "Code": mechanic.Code,
            "firstAvailableDate": mechanic.firstAvailableDate
        });
    },
    // eslint-disable-next-line max-statements,complexity,max-lines-per-function
    ADD_CHOSEN_PRODUCT (state, id) {
        const index = state.chosenProducts.findIndex((item) => item.id === id);
        if (index < 0) {
            const productsForAPKDiscount = ["jaarbeurt1", "jaarbeurt2", "jaarbeurt3", "jaarbeurt4", "onderhoudsbeurt"];
            const product = state.maintenanceProducts.find((item) => item.id === id);
            if (productsForAPKDiscount.indexOf(product.code.trim().toLowerCase()) !== -1) {
                const apkProduct = state.chosenProducts.find((item) => item.code.trim().toLowerCase() === "apk");
                if (apkProduct) {
                    apkProduct.price = 29;
                }
            }
            if (state.existingCustomer) {
                if (product.code === "aircoonderhoud" && state.currentCarInfo.laatsteaircovuldatum !== "") {
                    product.lastdate = `De vorige bij ons bekende airco service was op ${moment(state.currentCarInfo.laatsteaircovuldatum).format("D-MM-YYYY")}`;
                }
                if (product.code === "wisserbladen" && state.currentCarInfo.laatsteruitenwisserbladenvoordatum !== "") {
                    product.lastdate = `De vorige bij ons bekende ruitenwisserbladen vervanging was op ${moment(state.currentCarInfo.laatsteruitenwisserbladenvoordatum).format("D-MM-YYYY")}`;
                }
                if (product.code === "remvloeistof" && state.currentCarInfo.laatsteremvloeistofdatum !== "" && state.currentCarInfo.laatsteonderhoudkm !== "") {
                    product.lastdate = `De vorige bij ons bekende remvloeistof verversing was op ${moment(state.currentCarInfo.laatsteremvloeistofdatum).format("D-MM-YYYY")}`;
                }
                if (product.code === "onderhoudsbeurt" && state.currentCarInfo.laatsteonderhouddatum !== "" && state.currentCarInfo.laatsteonderhoudkm !== "") {
                    product.lastdate = `De vorige bij ons bekende onderhoudsbeurt was op ${moment(state.currentCarInfo.laatsteonderhouddatum).format("D-MM-YYYY")}
                met kilometerstand ${state.currentCarInfo.laatsteonderhoudkm}`;
                }
                // eslint-disable-next-line require-unicode-regexp
                const jaarBeurtProduct = (productsForAPKDiscount.indexOf(product.code.trim().toLowerCase()) !== -1);
                if (jaarBeurtProduct > 0 && state.currentCarInfo.laatsteonderhouddatum !== "") {
                    product.lastdate = `De vorige bij ons bekende onderhoudsbeurt was op ${moment(state.currentCarInfo.laatsteonderhouddatum).format("D-MM-YYYY")}
                met kilometerstand ${state.currentCarInfo.laatsteonderhoudkm}`;
                }
            }
            if (product.code === "apk" && state.currentRdwData.vervaldatum_apk_dt !== "") {
                product.lastdate = `De APK van uw voertuig is geldig tot en met ${moment(state.currentRdwData.vervaldatum_apk_dt).format("D-MM-YYYY")}`;
            }

            state.chosenProducts.push(product);
            state.Appointment.Jobs.push({
                "JobID": product.id,
                "Units": product.units,
                "SkillCode": product.skillCode,
                "Name": product.label,
                "Waits": product.waits,
                "Type": product.type,
                "Price": product.price,
                "DiscountPrice": product.discountPrice,
                "Code": product.code
            });
            const productsForMechanicalWash = ["jaarbeurt1", "jaarbeurt2", "jaarbeurt3", "jaarbeurt4", "onderhoudsbeurt"];
            if (productsForMechanicalWash.indexOf(product.code.trim().toLowerCase()) !== -1) {
                state.mechanicalWash = true;
            }
        }
    },
    ADD_ACTION_PRODUCT (state, id) {
        const index = state.chosenProducts.findIndex((item) => item.id === id);
        if (index > -1) {
            return;
        }
        const product = state.actionProducts.find((item) => item.id === id);
        state.chosenProducts.push(product);
        state.Appointment.Jobs.push({
            "JobID": product.id,
            "Units": product.units,
            "SkillCode": product.skillCode,
            "Name": product.label,
            "Waits": product.waits,
            "Type": product.type,
            "Price": product.price,
            "DiscountPrice": product.discountPrice,
            "Code": product.code
        });
    },
    SET_ACTION_PRODUCT_SHOWN (state, params) {
        const productIndex = state.actionProducts.findIndex((item) => item.id === params.id);
        const product = state.actionProducts.find((item) => item.id === params.id);
        product.shown = true;
        state.actionProducts.splice(
            productIndex,
            1,
            product
        );
    },
    // eslint-disable-next-line max-statements
    REMOVE_CHOSEN_PRODUCT (state, id) {
        const index = state.chosenProducts.findIndex((item) => item.id === id);
        if (index < 0) {
            return;
        }
        const product = state.maintenanceProducts.find((item) => item.id === id);
        const productsForMechanicalWash = ["jaarbeurt1", "jaarbeurt2", "jaarbeurt3", "jaarbeurt4", "onderhoudsbeurt"];
        const productsForAPKDiscount = ["jaarbeurt1", "jaarbeurt2", "jaarbeurt3", "jaarbeurt4", "onderhoudsbeurt"];
        if (productsForMechanicalWash.indexOf(product.code.trim().toLowerCase()) !== -1) {
            state.mechanicalWash = false;
        }
        if (productsForAPKDiscount.indexOf(product.code.trim().toLowerCase()) !== -1) {
            const apkProduct = state.chosenProducts.find((item) => item.code.trim().toLowerCase() === "apk");
            if (apkProduct) {
                apkProduct.price = 49;
            }
        }
        state.chosenProducts.splice(
            index,
            1
        );
        const indexJob = state.Appointment.Jobs.findIndex((item) => item.JobID === id);
        state.Appointment.Jobs.splice(
            indexJob,
            1
        );
    },
    ADD_APK_PRODUCT (state, products) {
        if (!state.apkInTwoMonths) {
            return;
        }
        const apkProduct = products.find((product) => product.code.trim().toLowerCase() === "apk");
        if (!apkProduct) {
            return;
        }
        const productsForDiscountAPK = ["jaarbeurt1", "jaarbeurt2", "jaarbeurt3", "jaarbeurt4", "onderhoudsbeurt"];
        let productMatch = false;
        state.chosenProducts.forEach((product) => {
            const productTrimmed = product.code.trim().toLowerCase();
            if (productsForDiscountAPK.includes(productTrimmed)) {
                productMatch = true;
            }
        });
        if (productMatch && state.doAdvice === true) {
            // Bertil and Pim concluded this is okay for now because the last price change was 6 years ago.
            apkProduct.price = 29;
        }
        state.chosenProducts.push(apkProduct);
        state.Appointment.Jobs.push({
            "JobID": apkProduct.id,
            "Units": apkProduct.units,
            "SkillCode": apkProduct.skillCode,
            "Name": apkProduct.label,
            "Waits": apkProduct.waits,
            "Type": apkProduct.type,
            "Price": apkProduct.price,
            "DiscountPrice": apkProduct.discountPrice,
            "Code": apkProduct.code
        });
    },
    ADD_TRA_PRODUCT (state, products) {
        const traProduct = products.find((product) => product.code.trim().toLowerCase() === "terugroepactie");
        if (!traProduct) {
            return;
        }
        if (!state.callbackAction) {
            traProduct.showFrontend = false;
            return;
        }
        state.chosenProducts.push(traProduct);
        state.Appointment.Jobs.push({
            "JobID": traProduct.id,
            "Units": traProduct.units,
            "SkillCode": traProduct.skillCode,
            "Name": traProduct.label,
            "Waits": traProduct.waits,
            "Type": traProduct.type,
            "Price": traProduct.price,
            "DiscountPrice": traProduct.discountPrice,
            "Code": traProduct.code
        });
    },
    UPDATE_PRODUCT_SET_SHOW_FRONTEND (state, bool) {
        const traProduct = state.maintenanceProducts.find((product) => product.code.trim().toLowerCase() === "terugroepactie");
        if (!traProduct) {
            return;
        }
        traProduct.showFrontend = bool;
    },
    ADD_DAMAGE_IMAGE (state, data) {
        state.Appointment.damageImages[data.index] = data.image;
    },
    UPDATE_VALUE (state, data) {
        state[data.name] = data.value;
    },
    UPDATE_VALUE_IN_OBJECT (state, data) {
        state[data.object][data.field] = data.value;
    },
    SET_MECHANIC_IMAGE_TO_DEFAULT (state, mechanicId) {
        const mechanic = state.mechanics.find((m) => (m.Id === mechanicId));
        mechanic.ImageUrl = "geen_voorkeur_voor_een_specifieke_monteur.png";
    },
    UPDATE_OBJECT (state, data) {
        _.set(
            state,
            data.object,
            data.value
        );
    },
    RESET_STATE (state) {
        Object.assign(
            state,
            getDefaultState()
        );
    },
    RESET_CHOSEN_PRODUCTS (state) {
        state.chosenProducts = [];
    },
    CLEAR_CURRENT_MECHANIC (state) {
        state.currentMechanic = {};
    },
    apiFailure (newState, value) {
        return value;
    }
};

// eslint-disable-next-line max-statements
function plateFormats () {
    const format = [];
    // eslint-disable-next-line line-comment-position
    format[0] = /^[a-zA-Z]{2}[0-9]{2}[0-9]{2}$/u; // XX-99-99
    // eslint-disable-next-line line-comment-position
    format[1] = /^[0-9]{2}[0-9]{2}[a-zA-Z]{2}$/u; // 99-99-XX
    // eslint-disable-next-line line-comment-position
    format[2] = /^[0-9]{2}[a-zA-Z]{2}[0-9]{2}$/u; // 99-XX-99
    // eslint-disable-next-line line-comment-position
    format[3] = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}$/u; // XX-99-XX
    // eslint-disable-next-line line-comment-position
    format[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[0-9]{2}$/u; // XX-XX-99
    // eslint-disable-next-line line-comment-position
    format[5] = /^[0-9]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/u; // 99-XX-XX
    // eslint-disable-next-line line-comment-position
    format[6] = /^[0-9]{2}[a-zA-Z]{3}[0-9]{1}$/u; // 99-XXX-9
    // eslint-disable-next-line line-comment-position
    format[7] = /^[0-9]{1}[a-zA-Z]{3}[0-9]{2}$/u; // 9-XXX-99
    // eslint-disable-next-line line-comment-position
    format[8] = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{1}$/u; // XX-999-X
    // eslint-disable-next-line line-comment-position
    format[9] = /^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{2}$/u; // X-999-XX
    // eslint-disable-next-line line-comment-position,no-warning-comments
    format[10] = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}$/u; // XXX-99-X
    // eslint-disable-next-line line-comment-position
    format[11] = /^[a-zA-Z]{1}[0-9]{2}[a-zA-Z]{3}$/u; // X-99-XXX
    // eslint-disable-next-line line-comment-position
    format[12] = /^[0-9]{1}[a-zA-Z]{2}[0-9]{3}$/u; // 9-XX-999
    // eslint-disable-next-line line-comment-position
    format[13] = /^[0-9]{3}[a-zA-Z]{2}[0-9]{1}$/u; // 999-XX-9
    return format;
}

const getters = {
    "currentCarInfo": (state) => state.currentCarInfo,
    // eslint-disable-next-line complexity,max-lines-per-function,max-statements
    "formattedLicensePlate": (state) => {
        let licensePlate = state.Appointment.Fields.RegistrationNumber;
        const licensePlateIndex = plateFormats().findIndex((item) => licensePlate.match(item));
        if (licensePlateIndex) {
            if (licensePlateIndex <= 5) {
                licensePlate = `${licensePlate.substr(
                    0,
                    2
                )} - ${licensePlate.substr(
                    2,
                    2
                )} - ${licensePlate.substr(
                    4,
                    2
                )}`;
            }
            if (licensePlateIndex === 7 || licensePlateIndex === 9) {
                licensePlate = `${licensePlate.substr(
                    0,
                    1
                )} - ${licensePlate.substr(
                    1,
                    3
                )} - ${licensePlate.substr(
                    4,
                    2
                )}`;
            }
            if (licensePlateIndex === 6 || licensePlateIndex === 8) {
                licensePlate = `${licensePlate.substr(
                    0,
                    2
                )} - ${licensePlate.substr(
                    2,
                    3
                )} - ${licensePlate.substr(
                    5,
                    1
                )}`;
            }
            if (licensePlateIndex === 10 || licensePlateIndex === 13) {
                licensePlate = `${licensePlate.substr(
                    0,
                    3
                )} - ${licensePlate.substr(
                    3,
                    2
                )} - ${licensePlate.substr(
                    5,
                    1
                )}`;
            }
            if (licensePlateIndex === 11 || licensePlateIndex === 12) {
                licensePlate = `${licensePlate.substr(
                    0,
                    1
                )} - ${licensePlate.substr(
                    1,
                    2
                )} - ${licensePlate.substr(
                    3,
                    3
                )}`;
            }
        }
        return licensePlate.toUpperCase();
    },
    "formattedWorkshopLicensePlate": (state) => {
        let licensePlateToFormat = state.Appointment.Fields.RegistrationNumber;
        licensePlateToFormat = licensePlateToFormat.replace(
            "-",
            ""
        ).replace(
            " ",
            ""
        );
        return licensePlateToFormat.toUpperCase();
    },
    "currentCarImage": (state) => state.currentCarImage,
    "maintenanceProducts": (state) => state.maintenanceProducts,
    "productList": (state) => (state.doAdvice ? state.chosenProducts : state.maintenanceProducts).filter((product) => (product.showFrontend)),
    "chosenProducts": (state) => state.chosenProducts,
    "actionProducts": (state) => state.actionProducts,
    // eslint-disable-next-line no-shadow
    "unChosenProducts": (state, getters) => getters.maintenanceProducts.filter((product) => !getters.isChosenProduct(product.id)).filter((product) => (product.showFrontend)),
    "isChosenProduct": (state) => (id) => {
        const prod = state.chosenProducts.find((product) => product.id === id);
        return (typeof (prod) !== "undefined");
    },
    "getProductByCode": (state) => (code) => state.maintenanceProducts.find((prod) => prod.code === code),
    "calculateTotalProductPrice": (state) => state.chosenProducts.reduce(
        (a, b) => {
            let price = parseFloat(
                b.price,
                10
            );
            if (isNaN(price)) {
                price = 0;
            }
            return a + price;
        },
        0
    ),
    "totalTimeUnits": (state) => {
      return Math.max(
        1,
        state.chosenProducts.reduce((accumulator, current) => {
          const unit = parseInt(current.units, 10) || 0;
          return accumulator + unit;
        }, 0)
      );
    },
    // eslint-disable-next-line consistent-return
    "getNextActionProduct": (state) => state.actionProducts.find((product) => (typeof (product.shown) === "undefined" || product.shown !== true)),
    "getFirstMechanicId": (state) => (state.mechanics.length > 0) ? state.mechanics[0].Id : null
};

const actions = {
    resetState ({commit}) {
        commit("RESET_STATE");
    },
    addProduct ({commit}, params) {
        commit(
            "ADD_CHOSEN_PRODUCT",
            params.id
        );
    },
    removeProduct ({commit}, params) {
        commit(
            "REMOVE_CHOSEN_PRODUCT",
            params.id
        );
    },
    changeChosenProduct ({commit}, params) {
        if (params.event) {
            commit(
                "ADD_CHOSEN_PRODUCT",
                params.id
            );
        } else {
            commit(
                "REMOVE_CHOSEN_PRODUCT",
                params.id
            );
        }
    },
    changeActionProduct ({commit}, params) {
        if (params.event) {
            commit(
                "ADD_ACTION_PRODUCT",
                params.id
            );
            commit(
                "SET_ACTION_PRODUCT_SHOWN",
                {
                    "shown": true,
                    "id": params.id
                }
            );
        } else {
            commit(
                "REMOVE_CHOSEN_PRODUCT",
                params.id
            );
            commit(
                "SET_ACTION_PRODUCT_SHOWN",
                {
                    "shown": false,
                    "id": params.id
                }
            );
        }
    },
    selectMechanic ({commit}, mechanic) {
        commit(
            "SELECT_MECHANIC",
            mechanic
        );
    },
    setMechanicImageToDefault ({commit}, mechanicId) {
        commit(
            "SET_MECHANIC_IMAGE_TO_DEFAULT",
            mechanicId
        );
    },
    clearMechanic ({commit}, mechanic) {
        commit(
            "CLEAR_CURRENT_MECHANIC",
            mechanic
        );
    },
    searchLicensePlate ({commit}, licensePlate) {
        return api.post(
            "v1/licenseplate/search",
            {"licenseplate": licensePlate},
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    searchNaw ({commit}, email) {
        return api.post(
            "v1/naw/search",
            {
                email,
                "klantnr": state.currentKlantNr
            },
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`

            }
        )
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    // eslint-disable-next-line max-lines-per-function
    getRdwData ({commit}, licensePlate) {
        moment.locale('nl');
        return api.get(
            `v1/rdw/bylicenseplate/${licensePlate}`,
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            // eslint-disable-next-line max-lines-per-function
            .then((response) => {
                if (response.data) {
                    commit(
                        "UPDATE_OBJECT",
                        {
                            "object": "currentRdwData",
                            "value": response.data
                        }
                    );

                    if (response.data.openstaande_terugroepactie_indicator === "Ja") {
                        commit(
                            "UPDATE_OBJECT",
                            {
                                "object": "callbackAction",
                                "value": true
                            }
                        );
                        const recallMakes = ['volvo', 'polestar', 'lynk&co'];
                        if(recallMakes.includes(response.data.merk.toLowerCase())) {
                            for (const recallIndex in response.data.recall_description) {
                                commit(
                                    "ADD_ALERT",
                                    "Uw auto heeft een terugroepactie. " + response.data.recall_description[recallIndex]
                                );
                            }
                        } else {
                            commit(
                                "ADD_ALERT",
                                "Uw auto heeft een terugroepactie."
                            )
                        }
                    } else {
                        commit(
                            "UPDATE_OBJECT",
                            {
                                "object": "hideCallbackProduct",
                                "value": true
                            }
                        );
                    }
                    const apkDate = moment(
                        response.data.vervaldatum_apk,
                        "YYYYMMDD"
                    );
                    if (apkDate.isBefore(moment().add(
                        2,
                        "M"
                    ))) {
                        commit(
                            "UPDATE_OBJECT",
                            {
                                "object": "apkInTwoMonths",
                                "value": true
                            }
                        );
                        commit(
                            "ADD_ALERT",
                            `Uw APK vervalt op ${apkDate.format("D MMMM YYYY")}`
                        );
                    }
                } else {
                    commit(
                        "UPDATE_OBJECT",
                        {
                            "object": "invalidLicense",
                            "value": true
                        }
                    );
                    commit(
                        "ADD_ALERT",
                        `Het ingevoerde kenteken ${licensePlate} kon niet gevonden worden.`
                    );
                }
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    getDisabledDates ({commit}) {
        const params =
            {"currentEstablishmentId": state.currentEstablishmentId,
                "products": state.chosenProducts,
                "waits": state.waitForMaintenance};
        if (state.currentMechanic.Id) {
            params.currentMechanic = state.currentMechanic.Id;
        }
        return api.post(
            "v1/planit/unavailabledates",
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .then((response) => {
                if (response.data) {
                    commit(
                        "UPDATE_OBJECT",
                        {
                            "object": "currentDisabledDates",
                            "value": response.data.dates
                        }
                    );
                    commit(
                        "UPDATE_OBJECT",
                        {
                            "object": "maxSelectableDate",
                            "value": moment(response.data.maxDate.Date, "YYYY-MM-DD")
                        }
                    );
                }
            });
    },
    // This gets all first available dates for each establishment.
    getFirstAvailablePlanDate ({commit}) {
        const params =
            {"currentEstablishmentId": state.currentEstablishmentId,
                "products": state.chosenProducts,
                "waits": state.waitForMaintenance};
        if (state.currentMechanic.Id) {
            params.currentMechanic = state.currentMechanic.Id;
        }
        return api.post(
            "v1/planit/getfirstavailabledate",
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .then((response) => {
                if (response.data) {
                    commit(
                        "UPDATE_OBJECT",
                        {
                            "object": "maintenanceDate",
                            "value": response.data
                        }
                    );
                }
            });
    },
    getTimes ({commit}, date) {
        commit(
            "REMOVE_ALERT",
            "Er is op deze dag wel een tijd beschikbaar maar niet voor alleen een APK."
        );
        const params =
            {"currentEstablishmentId": state.currentEstablishmentId,
                date,
                "currentMechanic": state.currentMechanic.Id,
                "products": state.chosenProducts,
                "waitsForMaintenance": state.waitForMaintenance};
        return api.post(
            "v1/planit/timesbydate",
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .then((response) => {
                if (response.data) {
                    const result = response.data.find(({ Available }) => Available > 0);
                    if(typeof result !== 'undefined') {
                        commit(
                            "UPDATE_OBJECT",
                            {
                                "object": "currentTimes",
                                "value": response.data
                            }
                        );
                    } else {
                        commit(
                            "ADD_ALERT",
                            "Er is op deze dag wel een tijd beschikbaar maar niet voor alleen een APK."
                        );
                        commit(
                            "UPDATE_OBJECT",
                            {
                                "object": "currentTimes",
                                "value": response.data
                            }
                        )
                    }
                }
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    addDamageImage ({commit}, data) {
        commit(
            "ADD_DAMAGE_IMAGE",
            data
        );
    },
    updatePathValue ({commit}, data) {
        data.value = typeof data.value === "undefined" ? "" : data.value;
        if (typeof (data.object) === "string") {
            commit(
                "UPDATE_OBJECT",
                data
            );
        } else if (Array.isArray(data.object)) {
            for (const object of data.object) {
                commit(
                    "UPDATE_OBJECT",
                    {
                        "value": data.value,
                        object
                    }
                );
            }
        }
    },
    getMaintenanceProducts ({commit}, params) {
        commit("RESET_CHOSEN_PRODUCTS");
        return api.post(
            "v1/product",
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`

            }
        )
            .then((response) => {
                commit(
                    "UPDATE_OBJECT",
                    {
                        "object": "maintenanceProducts",
                        "value": response.data
                    }
                );
                if (params.advice) {
                    for (const product of response.data) {
                        if (product.checked) {
                            commit(
                                "ADD_CHOSEN_PRODUCT",
                                product.id
                            );
                        }
                    }
                }
                commit(
                    "ADD_APK_PRODUCT",
                    response.data
                );
                commit(
                    "ADD_TRA_PRODUCT",
                    response.data
                );
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    getActionProducts ({commit}, params) {
        return api.post(
            "v1/product?actie=true",
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`

            }
        )
            .then((response) => {
                commit(
                    "UPDATE_OBJECT",
                    {
                        "object": "actionProducts",
                        "value": response.data
                    }
                );
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    getMechanics ({commit}, params) {
        commit("CLEAR_CURRENT_MECHANIC");
        const urlParams =
            {"currentEstablishmentId": params.establishmentId,
                "products": state.chosenProducts,
                "waits": state.waitForMaintenance};
        return api.post(
            `v1/mechanic`,
            urlParams,
            {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .then((response) => {
                commit(
                    "UPDATE_OBJECT",
                    {
                        "object": "mechanics",
                        "value": response.data
                    }
                );
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    confirmAppointment ({commit, state}) {
        return api.post(
            `v1/planit/confirmappointment/${state.currentEstablishmentId}`,
            state.Appointment,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    addAlert ({commit}, alert) {
        commit(
            "ADD_ALERT",
            alert
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
